import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="Amour Design" />

    <Link src="/">
      <StaticImage src="../images/amour_logowhite.png"></StaticImage>
      <h2>AMOUR DESIGN</h2> <br />
    </Link>
    <h3>We design expressions</h3>
    <p>
      communicate your thoughts and feelings,
      <br /> from heart, into art.
    </p>
  </Layout>
)

export default IndexPage
